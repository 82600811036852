<template>
  <div>
    <b-table :current-page="current_page"
             :fields="fields"
             :items="feedbacks"
             :per-page="per_page"
             class="table-responsive"
             head-variant="light"
             hover
             responsive
             striped>
      <!--DOESN'T WORK!-->
      <template v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="feedbacks"
                         :fields="fields"
                         :not_include_key="['actions','ordinalNumber']">
        </proposal-filter>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage:per_page, currentPage:current_page}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-action-expand-details v-if="elevated"
                               :b-table-row="row"/>

        <base-icon-button-send v-else
                               title="Go to feedback"
                               @click="go_to_document(row.item._id.$oid, row.item.feedbacks.user_id)"/>
      </template>

      <template v-slot:row-details="row">
        <b-card class="ma-4">
          <b-table :fields="feedback_fields"
                   :items="row.item.feedbacks"
                   :striped="false"
                   class="table-responsive"
                   hover
                   responsive
                   striped>
            <template v-slot:cell(actions)="row">
              <base-icon-button-send title="Go to feedback"
                                     @click="go_to_document(row.item.document_id, row.item.user_id)"/>
            </template>
          </b-table>
        </b-card>
      </template>

    </b-table>
    <b-table-footer v-model="loading"
                    :currentPage="current_page"
                    :data_length="feedbacks.length"
                    :perPage="per_page"
                    @change_page="change_page">
    </b-table-footer>
  </div>
</template>

<script>
  import BaseActionExpandDetails from '@/components/actions/BaseActionExpandDetails';
  import bTableFooter from '@/components/b_table_footer';
  import BaseIconButtonSend from '@/components/baseComponents/baseIcons/BaseIconButtonSend';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';

  export default {
    name: 'feedback_list',
    props: {
      load_data_url: {
        type: String,
        default: '',
      },
      elevated: false,
      prop_fields: {
        type: Array,
        default: () => [],
      },
      prop_feedback_fields: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      BaseActionExpandDetails,
      BaseIconButtonSend,
      BaseColumnOrdinalNumber,
      proposalFilter,
      bTableFooter,
    },
    data() {
      return {
        loading: false,
        current_page: 1,
        per_page: 20,
        feedbacks: [],
        id: 1,
        fields: this.prop_fields,
        feedback_fields: this.prop_feedback_fields,
      };
    },
    methods: {
      load_data() {
        this.loading = true;
        this.axios.get(this.load_data_url)
          .then((response) => {
            this.process_documents(response.data.documents);
            this.feedbacks = response.data.documents;
            this.$emit('setup_proposal', this.feedbacks);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      process_documents(documents) {
        if (documents.length >= 0) {
          for (const document of documents) {
            document.created_at = this.created_at(document);
            if (Array.isArray(document.feedbacks)) {
              for (const feedback of document.feedbacks) {
                feedback.document_id = document._id.$oid;
              }
            }
          }
        }
      },
      change_page(val) {
        this.current_page = val;
      },
      go_to_document(doc_id, user_id) {
        this.$router.push(`/feedbacks/${doc_id}/${user_id}`);
      },
    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>

</style>
